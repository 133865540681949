import * as Actions from './actions'
import ActionType from './action-type'
import {Api} from '../../../api/Api'
import {REST} from '../../../index'
import {ThunkAction} from 'redux-thunk'
import AppState from '../../types/app-state'
import {
    SavedFilterPageType,
    ResultMessageType,
    SearchType,
    AllSavedFilterType,
    SavedFilterSubscription,
    NotificationScheduleType,
    TemplateDefinitions,
    SubscriptionPayload,
} from './state'
import {GuidType} from '../../../values/generic-type-defintions'
import * as IncidentsAction from '../incidents-filter/action-creators'
import * as MetricsBetaAction from '../metrics-filter-beta/action-creators'
import * as NetworkAssetsAction from '../network-assets-filter/action-creators'
import * as UsbInventoryAction from '../usb-inventory-filter/action-creators'
import * as SoftwareInventoryAction from '../software-inventory-filter/action-creators'
import * as ReportsAction from '../reports-filter/action-creators'
import * as IncidentsReportAction from '../incidents-report-filter/action-creators'
import {IncidentsFilterCriteria} from '../../../values/user-preferences/incidents-filter'
import {NetworkAssetsFilterCriteria} from '../../../values/user-preferences/network-assets-filter'
import {UsbInventoryFilterCriteria} from '../../../values/user-preferences/usb-inventory-filter'
import {SoftwareInventoryFilterCriteria} from '../../../values/user-preferences/software-inventory-filter'
import {formatTimestampCriteria} from '../../../pages/software-inventory/contexts/context-helper'
import {SORT_ORDER} from '../../../values/sort-order'
import {getRelativeTimeRange} from '../../../utils/Utils'
import {MetricsBetaFilter} from '../metrics-filter-beta/state'
import {ReportsFilterCriteria} from '../../../values/user-preferences/reports-filter'
import {IncidentsReportFilter} from '../incidents-report-filter/state'

const SAVED_FILTERS_ENDPOINT = '/search-definitions/api/v1'

export function setSavedFilterPageType(pageType: SavedFilterPageType): Actions.SetPageTypeAction {
    return {
        type: ActionType.SET_PAGE_TYPE,
        payload: pageType,
    }
}

export const fetchSavedFilters = (): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        dispatch(requestSavedFilters())
        REST.get(`${SAVED_FILTERS_ENDPOINT}/searchDefinitions`).then((response) => {
            dispatch(receiveSavedFilters(response.data))
        })
        REST.get(`${SAVED_FILTERS_ENDPOINT}/templateDefinitions/summary`).then((response) => {
            dispatch(receiveTemplateDefinitionsSummary(response.data))
        })
    }
}

function requestSavedFilters(): Actions.RequestSavedFiltersAction {
    return {
        type: ActionType.REQUEST_SAVED_FILTERS,
    }
}

function receiveSavedFilters(payload: AllSavedFilterType[]): Actions.ReceiveSavedFiltersAction {
    return {
        type: ActionType.RECEIVE_SAVED_FILTERS,
        payload,
    }
}

export const deleteSavedFilter = (
    identity: GuidType,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch, getState) => {
        const pageType = getState().savedFilters.pageType
        dispatch(requestSavedFilters())
        REST.delete(`${SAVED_FILTERS_ENDPOINT}/searchDefinitions/${identity}`).then(() => {
            dispatch(fetchSavedFilters())
            if (pageType === SavedFilterPageType.INCIDENTS) {
                dispatch(IncidentsAction.resetFilter())
            } else if (pageType === SavedFilterPageType.METRICS) {
                dispatch(MetricsBetaAction.resetFilter())
            } else if (pageType === SavedFilterPageType.NETWORK_ASSETS) {
                dispatch(NetworkAssetsAction.resetFilter())
            } else if (pageType === SavedFilterPageType.USB_INVENTORY) {
                dispatch(UsbInventoryAction.resetFilter())
            } else if (pageType === SavedFilterPageType.SOFTWARE_INVENTORY) {
                dispatch(SoftwareInventoryAction.resetFilter())
            } else if (pageType === SavedFilterPageType.REPORTS) {
                dispatch(ReportsAction.resetFilter())
            } else if (pageType === SavedFilterPageType.INCIDENTS_REPORT) {
                dispatch(IncidentsReportAction.resetFilter())
            }
        })
    }
}

function receiveTemplateDefinitionsSummary(
    payload: TemplateDefinitions[] | undefined,
): Actions.ReceivedTemplateDefinitionsSummaryAction {
    return {
        type: ActionType.RECEIVED_TEMPLATE_DEFINITIONS_SUMMARY,
        payload,
    }
}

function receivedRequestedUserSubscriptions(
    payload: SavedFilterSubscription[] | undefined,
): Actions.ReceivedRequestedUserSubscriptionsAction {
    return {
        type: ActionType.RECEIVED_REQUESTED_USER_SUBSCRIPTIONS,
        payload,
    }
}

export function findUserSubscriptions(
    identity: GuidType,
): ThunkAction<void, AppState, Api, Actions.Action> {
    return (dispatch) => {
        REST.get(`${SAVED_FILTERS_ENDPOINT}/subscriptions/user/${identity}`).then((response) => {
            dispatch(receivedRequestedUserSubscriptions(response.data))
        })
    }
}

export const createNewUserSubscription = (
    newSubscription: SubscriptionPayload,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        REST.post(`${SAVED_FILTERS_ENDPOINT}/subscriptions`, {
            ...newSubscription,
            notifyOnChange: newSubscription.notifyOnChange ?? false,
        }).then(() => {
            dispatch(resetAlertChanges())
            dispatch(fetchSavedFilters())
            dispatch(findUserSubscriptions(newSubscription.userId))
        })
    }
}

export const updateUserSubscription = (
    updatedSubscription: SubscriptionPayload,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch) => {
        REST.put(`${SAVED_FILTERS_ENDPOINT}/subscriptions`, updatedSubscription).then(() => {
            dispatch(resetAlertChanges())
            dispatch(fetchSavedFilters())
            dispatch(findUserSubscriptions(updatedSubscription.userId))
        })
    }
}

export const deleteUserSubscription = (
    identity: GuidType,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch, getState) => {
        dispatch(requestSavedFilters())
        REST.delete(`${SAVED_FILTERS_ENDPOINT}/subscriptions/${identity}`).then(() => {
            dispatch(resetAlertChanges())
            dispatch(fetchSavedFilters())
            dispatch(findUserSubscriptions(getState().currentUser.currentUser.user))
        })
    }
}

export function openSaveFilterPopup(value: boolean): Actions.OpenSaveFilterPopupAction {
    return {
        type: ActionType.OPEN_SAVE_FILTER_POPUP,
        payload: value,
    }
}

export const saveNewIncidentsFilter = (
    name: string,
    page: SavedFilterPageType,
    closePopup: (value: boolean) => void,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch, getState) => {
        const currentFilter = getState().incidentsFilter
        const criteria = {
            orderBy: {
                column: currentFilter.orderIncidentsBy.column,
                ascending: currentFilter.orderIncidentsBy.direction === SORT_ORDER.ASCENDING,
            },
            updatedFrom: currentFilter.selectedUpdatedFromTimeRange,
            updatedTo: currentFilter.selectedUpdatedToTimeRange,
            createdFrom: currentFilter.selectedCreatedFromTimeRange,
            createdTo: currentFilter.selectedCreatedToTimeRange,
            updatedFromRelative: getRelativeTimeRange(currentFilter.updatedFromRelative),
            updatedToRelative: getRelativeTimeRange(currentFilter.updatedToRelative),
            createdFromRelative: getRelativeTimeRange(currentFilter.createdFromRelative),
            createdToRelative: getRelativeTimeRange(currentFilter.createdToRelative),
            text: currentFilter.searchIncidentNo,
            severities: currentFilter.filterBySeverities,
            statuses: currentFilter.filterByStatus,
            type: currentFilter.selectedIncidentType,
            assignedTo: currentFilter.assignedTo,
            guestAssignedToEmail: currentFilter.guestAssignedToEmail,
            watchedByCurrentUser: currentFilter.watchedByCurrentUser,
            unseenIncidentResponses: currentFilter.unseenIncidentResponses,
            assignedToVesselEmail: currentFilter.assignedToVesselEmail,
        } as IncidentsFilterCriteria

        REST.post(`${SAVED_FILTERS_ENDPOINT}/searchDefinitions`, {
            criteria,
            name,
            page,
            searchType: SearchType.INDIVIDUAL,
        })
            .then((res) => {
                dispatch(fetchSavedFilters())
                dispatch(
                    IncidentsAction.setSelectedFilterName(name, {
                        savedFilter: res.data,
                        sortByNotes: currentFilter.sortByNotes,
                        detailExpanded: currentFilter.detailExpanded,
                    }),
                )
                dispatch(setResultMessage(ResultMessageType.SUCCESS))
                setTimeout(() => {
                    dispatch(setResultMessage(undefined))
                    closePopup(false)
                }, 1000)
            })
            .catch(() => {
                dispatch(setResultMessage(ResultMessageType.FAILED))
                setTimeout(() => dispatch(setResultMessage(undefined)), 3000)
            })
    }
}

export const saveNewMetricsBetaFilter = (
    name: string,
    page: SavedFilterPageType,
    closePopup: (value: boolean) => void,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch, getState) => {
        const currentFilter = getState().metricsBetaFilter
        const criteria = {
            orderBy: currentFilter.orderBy,
            metricTypes: currentFilter.metricTypes,
            onlyAssetsWithIssues: currentFilter.onlyAssetsWithIssues,
            selectedAnalysisPeriod: currentFilter.selectedAnalysisPeriod,
            analysisTypes: currentFilter.analysisTypes,
            selectedViewScreenType: currentFilter.selectedViewScreenType,
            assetValues: currentFilter.assetValues,
        } as MetricsBetaFilter

        REST.post(`${SAVED_FILTERS_ENDPOINT}/searchDefinitions`, {
            criteria,
            name,
            page,
            searchType: SearchType.INDIVIDUAL,
        })
            .then((res) => {
                dispatch(fetchSavedFilters())
                dispatch(MetricsBetaAction.setSelectedFilterName(name, {savedFilter: res.data}))
                dispatch(setResultMessage(ResultMessageType.SUCCESS))
                setTimeout(() => {
                    dispatch(setResultMessage(undefined))
                    closePopup(false)
                }, 1000)
            })
            .catch(() => {
                dispatch(setResultMessage(ResultMessageType.FAILED))
                setTimeout(() => dispatch(setResultMessage(undefined)), 3000)
            })
    }
}

export const saveNewNetworkAssetFilter = (
    name: string,
    page: SavedFilterPageType,
    closePopup: (value: boolean) => void,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch, getState) => {
        const currentFilter = getState().networkAssetsFilter

        const vlanQuery = currentFilter.properties
            ? currentFilter.properties.includes(':')
                ? undefined
                : currentFilter.properties
            : undefined
        const macAddressQuery = currentFilter.properties
            ? currentFilter.properties.includes(':')
                ? currentFilter.properties
                : undefined
            : undefined

        const criteria = {
            orderBy: currentFilter.orderBy,
            states: currentFilter.states,
            networks: currentFilter.networks
                ? currentFilter.networks.map((e) => e.toLowerCase())
                : undefined,
            behaviours: currentFilter.behaviours,
            vlan: vlanQuery,
            macAddress: macAddressQuery,
            fromRelativeLastSeen: currentFilter.fromRelativeLastSeen,
            pagination: {pageSize: 10, pageOffset: 0},
        } as NetworkAssetsFilterCriteria

        REST.post(`${SAVED_FILTERS_ENDPOINT}/searchDefinitions`, {
            criteria,
            name,
            page,
            searchType: SearchType.INDIVIDUAL,
        })
            .then((res) => {
                dispatch(fetchSavedFilters())
                dispatch(NetworkAssetsAction.setSelectedFilterName(name, res.data))
                dispatch(setResultMessage(ResultMessageType.SUCCESS))
                setTimeout(() => {
                    dispatch(setResultMessage(undefined))
                    closePopup(false)
                }, 1000)
            })
            .catch(() => {
                dispatch(setResultMessage(ResultMessageType.FAILED))
                setTimeout(() => dispatch(setResultMessage(undefined)), 3000)
            })
    }
}

export const saveNewUsbInventoryFilter = (
    name: string,
    page: SavedFilterPageType,
    closePopup: (value: boolean) => void,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch, getState) => {
        const currentFilter = getState().usbInventoryFilter
        const criteria = {
            orderBy: {
                column: currentFilter.sortColumn.orderBy,
                isAscending: currentFilter.sortColumn.orderAscending,
            },
            deviceStatuses: currentFilter.selectedUsbDeviceStatus,
            fromRelativeFirstSeen: formatTimestampCriteria(currentFilter.searchedFirstDetected),
            fromRelativeLastSeen: formatTimestampCriteria(currentFilter.searchedLastActive),
            vendorName: currentFilter.searchedVendor,
            productName: currentFilter.searchedProductName,
            deviceTypes: currentFilter.searchedType,
            hasPurpose: currentFilter.hasPurpose,
            pagination: {pageSize: 10, pageOffset: 0},
        } as UsbInventoryFilterCriteria

        REST.post(`${SAVED_FILTERS_ENDPOINT}/searchDefinitions`, {
            criteria,
            name,
            page,
            searchType: SearchType.INDIVIDUAL,
        })
            .then((res) => {
                dispatch(fetchSavedFilters())
                dispatch(
                    UsbInventoryAction.setSelectedFilterName(name, {
                        savedFilter: res.data,
                        detailExpanded: currentFilter.detailExpanded,
                    }),
                )
                dispatch(setResultMessage(ResultMessageType.SUCCESS))
                setTimeout(() => {
                    dispatch(setResultMessage(undefined))
                    closePopup(false)
                }, 1000)
            })
            .catch(() => {
                dispatch(setResultMessage(ResultMessageType.FAILED))
                setTimeout(() => dispatch(setResultMessage(undefined)), 3000)
            })
    }
}

export const saveNewSoftwareInventoryFilter = (
    name: string,
    page: SavedFilterPageType,
    closePopup: (value: boolean) => void,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch, getState) => {
        const currentFilter = getState().softwareInventoryFilter
        const criteria = {
            orderBy: {
                column: currentFilter.sortColumn.orderBy,
                isAscending: currentFilter.sortColumn.orderAscending,
            },
            fromRelativeFirstSeen: formatTimestampCriteria(currentFilter.searchedFirstDetected),
            fromRelativeLastSeen: formatTimestampCriteria(currentFilter.searchedLastActive),
            company: currentFilter.searchedCompanyName,
            product: currentFilter.searchedProductName,
            statuses: currentFilter.filteredSoftwareStatus,
            pagination: {pageSize: 10, pageOffset: 0},
        } as SoftwareInventoryFilterCriteria

        REST.post(`${SAVED_FILTERS_ENDPOINT}/searchDefinitions`, {
            criteria,
            name,
            page,
            searchType: SearchType.INDIVIDUAL,
        })
            .then((res) => {
                dispatch(fetchSavedFilters())
                dispatch(
                    SoftwareInventoryAction.setSelectedFilterName(name, {
                        savedFilter: res.data,
                        detailExpanded: currentFilter.detailExpanded,
                    }),
                )
                dispatch(setResultMessage(ResultMessageType.SUCCESS))
                setTimeout(() => {
                    dispatch(setResultMessage(undefined))
                    closePopup(false)
                }, 1000)
            })
            .catch(() => {
                dispatch(setResultMessage(ResultMessageType.FAILED))
                setTimeout(() => dispatch(setResultMessage(undefined)), 3000)
            })
    }
}

export const saveNewReportsFilter = (
    name: string,
    page: SavedFilterPageType,
    closePopup: (value: boolean) => void,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch, getState) => {
        const currentFilter = getState().reportsFilter
        const criteria = {
            selectedReportType: currentFilter.selectedReportType,
            selectedRating: currentFilter.selectedRating,
            selectedFromTimeRange: currentFilter.selectedFromTimeRange,
            selectedToTimeRange: currentFilter.selectedToTimeRange,
            includeWholeFleetReports: currentFilter.includeWholeFleetReports,
            pagination: {pageSize: 10, pageOffset: 0},
        } as ReportsFilterCriteria

        REST.post(`${SAVED_FILTERS_ENDPOINT}/searchDefinitions`, {
            criteria,
            name,
            page,
            searchType: SearchType.INDIVIDUAL,
        })
            .then((res) => {
                dispatch(fetchSavedFilters())
                dispatch(
                    ReportsAction.setSelectedFilterName(name, {
                        savedFilter: res.data,
                    }),
                )
                dispatch(setResultMessage(ResultMessageType.SUCCESS))
                setTimeout(() => {
                    dispatch(setResultMessage(undefined))
                    closePopup(false)
                }, 1000)
            })
            .catch(() => {
                dispatch(setResultMessage(ResultMessageType.FAILED))
                setTimeout(() => dispatch(setResultMessage(undefined)), 3000)
            })
    }
}

export const saveNewIncidentsReportFilter = (
    name: string,
    page: SavedFilterPageType,
    closePopup: (value: boolean) => void,
): ThunkAction<void, AppState, Api, Actions.Action> => {
    return (dispatch, getState) => {
        const currentFilter = getState().incidentsReportFilter
        const criteria = {
            fromDate: currentFilter.fromDate,
            toDate: currentFilter.toDate,
        } as IncidentsReportFilter

        REST.post(`${SAVED_FILTERS_ENDPOINT}/searchDefinitions`, {
            criteria,
            name,
            page,
            searchType: SearchType.INDIVIDUAL,
        })
            .then((res) => {
                dispatch(fetchSavedFilters())
                dispatch(
                    IncidentsReportAction.setSelectedFilterName(name, {
                        savedFilter: res.data,
                    }),
                )
                dispatch(setResultMessage(ResultMessageType.SUCCESS))
                setTimeout(() => {
                    dispatch(setResultMessage(undefined))
                    closePopup(false)
                }, 1000)
            })
            .catch(() => {
                dispatch(setResultMessage(ResultMessageType.FAILED))
                setTimeout(() => dispatch(setResultMessage(undefined)), 3000)
            })
    }
}

export function setResultMessage(
    message: ResultMessageType | undefined,
): Actions.SetResultMessageAction {
    return {
        type: ActionType.SET_RESULT_MESSAGE,
        payload: message,
    }
}

export function setInactiveComponent(value: boolean): Actions.SetInactiveComponentAction {
    return {
        type: ActionType.SET_INACTIVE_COMPONENT,
        payload: value,
    }
}

export function openNotifyMePopup(value: boolean): Actions.OpenNotifyMePopupAction {
    return {
        type: ActionType.OPEN_NOTIFY_ME_POPUP,
        payload: value,
    }
}

export function setCurrentAppliedSavedFilter(
    value: AllSavedFilterType | undefined,
): Actions.SetCurrentAppliedSavedFilterAction {
    return {
        type: ActionType.SET_CURRENT_APPLIED_SAVED_FILTER,
        payload: value,
    }
}

export function setChangeAlertSchedule(
    value: NotificationScheduleType,
): Actions.SetChangeAlertScheduleAction {
    return {type: ActionType.SET_CHANGE_ALERT_SCHEDULE, payload: value}
}

export function setChangeAlertEmail(value: string): Actions.SetChangeAlertEmailAction {
    return {type: ActionType.SET_CHANGE_ALERT_EMAIL, payload: value}
}

export function setChangeAlertPrefix(value: string): Actions.SetChangeAlertPrefixAction {
    return {type: ActionType.SET_CHANGE_ALERT_PREFIX, payload: value}
}

export function setNotificationOnChange(value: boolean): Actions.SetNotificationOnChangeAction {
    return {type: ActionType.SET_NOTIFY_ON_CHANGE, payload: value}
}

export function resetAlertChanges(): Actions.ResetAlertChangesAction {
    return {type: ActionType.RESET_ALERT_CHANGES}
}
