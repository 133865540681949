import {useDispatch} from 'react-redux'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import {ContentContainerTop} from '../../../../templates/fixed-page/content-container-top.styled'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {Container} from './data-actions.styled'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {
    vesselFilterSelector,
    vesselsFilterLoadingStateSelector,
} from '../../../../store/state/vessel-filter/selectors'
import LoadingState from '../../../../values/loading-state-enum'
import {setVesselFilterPref} from '../../../../store/state/vessel-filter/action-creators'
import {DEFAULT_VESSEL_FILTER_STATE} from '../../../../store/state/vessel-filter/types/default-vessel-filter-state'
import {PageType} from '../../../../components/vessel-filters/data-helper'
import {useEffect} from 'react'
import {compareFilters, findIfDefaultFilterIsChanged} from '../data-helper'
import {savedMetricsBetaFiltersSelector} from '../../../../store/state/saved-filters/selectors'
import {FilterBarButtonWrapper} from './filter-bar-button-wrapper'
import {ViewScreenLevel} from './view-screen-level'
import {metricsBetaFilterSelector} from '../../../../store/state/metrics-filter-beta/selectors'
import {
    DEFAULT_METRICS_BETA_FILTER,
    MetricsBetaFilter,
} from '../../../../store/state/metrics-filter-beta/state'
import {setSelectedFilterName} from '../../../../store/state/metrics-filter-beta/action-creators'
import {getFormattedMetricsBetaFilter} from '../../../../components/saved-search/components/shared/helper'

export function DataActions(): JSX.Element {
    const {width} = useDimensions()
    const {setFilter, refreshData} = usePagedMetricsBeta()
    const dispatch = useDispatch()

    const {
        metricTypes,
        selectedAnalysisPeriod,
        locations,
        searchVesselTagTerm,
        searchVesselNameTerm,
        selectedFilterName,
        selectedViewScreenType,
        analysisTypes,
        onlyAssetsWithIssues,
        orderBy,
        assetValues,
        benchmarkType,
    } = useTypedSelector(metricsBetaFilterSelector)
    const newFilter = useTypedSelector(metricsBetaFilterSelector)
    const currentFilter = useTypedSelector(savedMetricsBetaFiltersSelector).find(
        (filter) => filter.name === selectedFilterName,
    )?.criteria
    const vesselFilterLocations = useTypedSelector(vesselFilterSelector).locations
    const vesselFilterTagTerm = useTypedSelector(vesselFilterSelector).searchVesselTagTerm
    const vesselFilterNameTerm = useTypedSelector(vesselFilterSelector).searchVesselNameTerm
    const vesselFilterLoading = useTypedSelector(vesselsFilterLoadingStateSelector)
    const isNotPopulated = vesselFilterLoading === LoadingState.NotPopulated

    useEffect(() => {
        if (isNotPopulated) {
            dispatch(
                setVesselFilterPref(
                    {
                        ...DEFAULT_VESSEL_FILTER_STATE,
                        locations: locations,
                        searchVesselTagTerm: searchVesselTagTerm,
                        searchVesselNameTerm: searchVesselNameTerm,
                    },
                    PageType.METRICS,
                ),
            )
        }
    }, [dispatch, searchVesselNameTerm, searchVesselTagTerm, locations, vesselFilterLoading])

    useEffect(() => {
        const filterLocations = isNotPopulated ? locations : vesselFilterLocations
        const filterTagTerm = isNotPopulated ? searchVesselTagTerm : vesselFilterTagTerm
        const filterNameTerm = isNotPopulated ? searchVesselNameTerm : vesselFilterNameTerm

        // Compare the current filter with the default state
        const isDefaultFilter = findIfDefaultFilterIsChanged(
            DEFAULT_METRICS_BETA_FILTER,
            filterLocations,
            metricTypes,
            selectedAnalysisPeriod,
            filterTagTerm,
            filterNameTerm,
            selectedViewScreenType,
            analysisTypes,
            assetValues,
        )
        if (isDefaultFilter) {
            refreshData()
        } else {
            compareFilters(
                currentFilter
                    ? getFormattedMetricsBetaFilter(currentFilter as MetricsBetaFilter)
                    : DEFAULT_METRICS_BETA_FILTER,
                newFilter,
            )
                ? dispatch(setSelectedFilterName('Unsaved filter'))
                : dispatch(setSelectedFilterName(selectedFilterName))
            setFilter(
                metricTypes,
                selectedAnalysisPeriod,
                filterLocations,
                filterTagTerm,
                filterNameTerm,
                selectedViewScreenType,
                analysisTypes,
                orderBy,
                onlyAssetsWithIssues,
                assetValues,
                benchmarkType,
            )
        }
    }, [
        metricTypes,
        selectedAnalysisPeriod,
        vesselFilterLocations,
        vesselFilterTagTerm,
        vesselFilterNameTerm,
        vesselFilterLoading,
        locations,
        searchVesselTagTerm,
        searchVesselNameTerm,
        selectedViewScreenType,
        analysisTypes,
        dispatch,
        onlyAssetsWithIssues,
        orderBy,
        assetValues,
        benchmarkType,
    ])

    return (
        <ContentContainerTop width={width}>
            <Container id="data-actions-container">
                <FilterBarButtonWrapper />
                <ViewScreenLevel />
            </Container>
        </ContentContainerTop>
    )
}
