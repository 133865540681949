import {DetailsButton} from './details-button/details-button'
import {AssetThreatScore} from './asset-threat-score/asset-threat-score'
import {useState} from 'react'
import {Icon} from '@cyberowlteam/cyberowl-ui-components'
import {TopSection} from './_styled/top-section.styled'
import {BottomSection} from './_styled/bottom-section.styled'
import {Title} from './_styled/title.styled'
import {AssetValue} from './_styled/asset-value.styled'
import {ExpandButton} from './_styled/expand-button.styled'
import {Label} from './_styled/label.styled'
import {Value} from './_styled/value.styled'
import {useDimensions} from '../../../../../contexts/dimensions/use-dimensions'
import {getGridLayout} from '../helpers'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {GuidType} from '../../../../../values/generic-type-defintions'
import {assetDetailsCachedReselector} from './re-reselector/asset-details-cached-selector'
import {WatchListButton} from '../watchlist-button/watchlist-button'
import {PlaceholderType} from '../../../../../values/placeholder'
import {useDispatch} from 'react-redux'
import {logButtonClickFor} from '../../../../../store/state/audit-log/action-creators'

interface AssetDetailsProps {
    assetId: GuidType
    where: PlaceholderType
}

export function AssetDetails({assetId, where}: AssetDetailsProps): JSX.Element {
    const {width} = useDimensions()
    const gridLayout = getGridLayout(width)
    const [expanded, setExpanded] = useState(false)
    const mappedAsset = useTypedSelector((state) => assetDetailsCachedReselector(state, assetId))
    const dispatch = useDispatch()
    function onClickWrapper() {
        if (expanded) {
            dispatch(
                logButtonClickFor(
                    `collapseAssetDetails_nodeScore:${mappedAsset.assetScore}_nodeValue:${mappedAsset.assetValueLevel}`,
                    'dashboard-fleetlist',
                ),
            )
        } else {
            dispatch(
                logButtonClickFor(
                    `expandAssetDetails_nodeScore:${mappedAsset.assetScore}_nodeValue:${mappedAsset.assetValueLevel}`,
                    'dashboard-fleetlist',
                ),
            )
        }
        setExpanded(!expanded)
    }
    return (
        <>
            <TopSection expanded={expanded}>
                <AssetThreatScore
                    score={mappedAsset?.assetScore || 0}
                    value={mappedAsset?.assetValueLevel || 'Unknown'}
                    expanded={expanded}
                    id={`${where}-asset-details-threat-score-${mappedAsset.assetId}`}
                />
                <Title id={`${where}-asset-details-title-${mappedAsset.assetId}`}>
                    {mappedAsset.assetName}
                </Title>
                <AssetValue id={`${where}-asset-details-value-level${mappedAsset.assetId}`}>
                    {mappedAsset.assetValueLevel} Value
                </AssetValue>
                <ExpandButton
                    onClick={onClickWrapper}
                    id={`${where}-asset-details-expand-button-${mappedAsset.assetId}`}
                >
                    <Icon glyph={expanded ? 'DropdownUp' : 'DropdownDown'} height={15} width={15} />
                </ExpandButton>
            </TopSection>
            {expanded && (
                <BottomSection gridLayout={gridLayout}>
                    <Label gridRow={1}>Time Stamp</Label>
                    <Value id={`${where}-asset-details-timestamp-${mappedAsset.assetId}`}>
                        {mappedAsset.lastEvent}
                    </Value>
                    <WatchListButton assetId={mappedAsset.assetId} where={where} />
                    <Label gridRow={2}>IP Address</Label>
                    <Value id={`${where}-asset-details-ip-address${mappedAsset.assetId}`}>
                        {mappedAsset.ipAddress}
                    </Value>
                    <DetailsButton
                        linkTo={`/dashboard?assetDetails=${mappedAsset.assetId}&category=alerts`}
                        uniqueId={mappedAsset.assetId}
                        where={where}
                    />
                </BottomSection>
            )}
        </>
    )
}
