import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import * as Styled from './metrics-headers.styled'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {GetMetricTypeContent} from './get-metric-type-content'
import {NoData} from '../no-data/no-data'

export function MetricsSummaries(): JSX.Element {
    const {width} = useDimensions()
    const {showFilterBar, metricTypesArray} = usePagedMetricsBeta()
    return (
        <Styled.ScrollableContent id="scrollable-content">
            {!metricTypesArray?.length ? (
                <NoData text="There are no metrics matching your filtering criteria" />
            ) : (
                <Styled.ContentLevelGrid
                    width={width}
                    showFilterBar={showFilterBar}
                    id="metrics-summaries-grid"
                >
                    {metricTypesArray &&
                        metricTypesArray?.map((metricType) => (
                            <GetMetricTypeContent key={metricType.name} metricType={metricType} />
                        ))}
                </Styled.ContentLevelGrid>
            )}
        </Styled.ScrollableContent>
    )
}
