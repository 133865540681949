import {Dispatch, useContext} from 'react'
import {PagedMetricsBetaContext} from './paged-metrics-beta-context'
import {UsePagedMetricsResult} from './use-paged-metrics-beta-output'
import * as ActionCreators from './state/action-creators'
import {AllActions} from './state/actions'
import {warn} from '../../../helpers/logging'
import {REST} from '../../..'
import {Location, LocationIdType} from '../../../store/state/locations/state'
import useTypedSelector from '../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../store/state/locations/selectors'
import {vesselFilterSelector} from '../../../store/state/vessel-filter/selectors'
import {
    ApiMetricsTableMap,
    MetricModalDetails,
    MetricsResponse,
    MetricsResponseData,
    MetricsResponseForAssetStatus,
    MetricsResponseForBenchmark,
    MetricsResponseForTarget,
    MetricsResponseForTrends,
    MetricTableHeaderForUI,
    MetricType,
    UNDEFINED_METRIC_MODAL_DETAILS,
} from './types/metrics-response'
import {metricsBetaFilterSelector} from '../../../store/state/metrics-filter-beta/selectors'
import {
    AnalysisType,
    BenchmarkTrendsType,
    MetricsSortType,
    SortColumnType,
    TimestampFilterType,
    ViewScreenLevelType,
} from '../../../store/state/metrics-filter-beta/state'
import {
    AllCustomerBenchmarksData,
    AssetsAffected,
    MetricContextDataMap,
    MetricSummmaryMap,
} from './types/metrics-summary'
import {filteredVesselIds} from './reselector/location-selection-number-reselector'
import {calculateScoreForCards} from './context-data-helper'
import {HighlightedFilerValueType} from './types/highlighted-filter-type'
import {DetailExpandedType, ProcessingStatus} from './types/modal-types'
import {
    convertPeriodTimestampForTrends,
    getFormatedPercentNumber,
    getMainMetricsDisplayName,
} from '../components/data-helper'
import {StringUtils} from '../../../utils/Utils'
import {MetricsPolicy} from '../../policy-management/contexts/metrics-policy/type/metrics-policy'
import {GuidType} from '../../../values/generic-type-defintions'
import {MetricsSchema} from '../../policy-management/contexts/metrics-policy/type/metrics-policy-schema'
import {NodeValue} from '../../../values/nodes/NodeData'
import LoadingState from '../../../values/loading-state-enum'
import {
    filterFormattedMetricData,
    allFormattedMetricsForUISelector,
} from '../../../store/state/metric-types/selectors'
import {FormattedMetricData} from '../../../store/state/metric-types/state'

const METRICS_ENDPOINT = 'metrics/api/v1'
const METRICS_POLICY_ENDPOINT = '/metrics/api/v1/policies'
const METRICS_SCHEMA_ENDPOINT = 'metrics/api/v1/metrics/schema'

export function usePagedMetricsBeta(): UsePagedMetricsResult {
    const {state, dispatch} = useContext(PagedMetricsBetaContext)
    if (state == undefined || dispatch == undefined) {
        throw new Error('usePagedMetricsBeta must be used within a PagedMetricsBetaContext')
    }
    const allLocations = useTypedSelector(locationsSelector)
    const currentFilter = useTypedSelector(metricsBetaFilterSelector)
    const currentVesselFilter = useTypedSelector(vesselFilterSelector)
    const allFormattedMetricsForUI = useTypedSelector(allFormattedMetricsForUISelector)

    function loadInitialPage(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.requestInitialPageData())

        getDataForMetricCards(
            dispatch,
            currentFilter.metricTypes,
            allLocations,
            currentFilter.selectedAnalysisPeriod,
            currentVesselFilter.locations,
            currentVesselFilter.searchVesselTagTerm,
            currentVesselFilter.searchVesselNameTerm,
            currentFilter.selectedViewScreenType,
            currentFilter.analysisTypes,
            currentFilter.assetValues,
            currentFilter.benchmarkType,
        )
    }

    function selectPage(requestedPage: number | undefined): void {
        if (dispatch == undefined) {
            warn('dispatch is undefined')
            return
        }
        if (requestedPage == undefined) {
            warn('requestedPage is undefined')
            return
        }
        if (state.idForMetricDetailsModal != null) {
            dispatch(ActionCreators.closeMetricsDetailsModal())
        }
        const offset = requestedPage * state.pageSize
        dispatch(ActionCreators.requestPageData(requestedPage))

        getTablePagedData(
            dispatch,
            offset,
            state.pageSize,
            currentFilter.metricTypes,
            allLocations,
            currentFilter.selectedAnalysisPeriod,
            currentFilter.onlyAssetsWithIssues,
            currentFilter.orderBy,
            currentVesselFilter.locations,
            currentVesselFilter.searchVesselTagTerm,
            currentVesselFilter.searchVesselNameTerm,
            currentFilter.analysisTypes,
            currentFilter.assetValues,
        )
    }

    function displayFilterBar(displayFilterBar: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.displayFilterBar(displayFilterBar))
    }

    function setFilter(
        selectedMetricTypes: MetricType[] | undefined,
        selectedPeriod: TimestampFilterType,
        searchedVessels: Set<LocationIdType> | undefined,
        searchedVesselTagTerm: string[],
        searchedVesselNameTerm: string,
        selectedViewScreenType: ViewScreenLevelType,
        analysisTypes: AnalysisType,
        sortColumn: SortColumnType,
        onlyAssetsWithIssues: boolean,
        assetValues: NodeValue[] | undefined,
        benchmarkType: BenchmarkTrendsType,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setFilter())
        if (selectedViewScreenType === 'Table') {
            getTablePagedData(
                dispatch,
                0,
                state.pageSize,
                selectedMetricTypes,
                allLocations,
                selectedPeriod,
                onlyAssetsWithIssues,
                sortColumn,
                searchedVessels,
                searchedVesselTagTerm,
                searchedVesselNameTerm,
                analysisTypes,
                assetValues,
            )
        } else {
            getDataForMetricCards(
                dispatch,
                selectedMetricTypes,
                allLocations,
                selectedPeriod,
                searchedVessels,
                searchedVesselTagTerm,
                searchedVesselNameTerm,
                selectedViewScreenType,
                analysisTypes,
                assetValues,
                benchmarkType,
            )
        }
    }

    function setActiveSavedFilterDropdown(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setSavedFilterDropdown(value))
    }

    function changePreviousPage(
        value: ViewScreenLevelType,
        previousMetricTypesSelection: MetricType[] | undefined,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setPreviousPage(value, previousMetricTypesSelection))
    }

    function displayHighlightedFilterValue(value: HighlightedFilerValueType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setHighlightedFilterValue(value))
    }

    function toggleModalDetailsExpanded(detailExpanded: DetailExpandedType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.toggleModalDetailsExpanded(detailExpanded))
    }
    async function displayMetricsDetailsModal(
        values:
            | MetricsResponseForAssetStatus
            | MetricsResponseForBenchmark
            | MetricsResponseForTrends
            | MetricsResponseForTarget,
        index: number,
    ): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.requestModalData(values.metric, values.location, index))

        const findMetricDetailsForModal = await findDetaisFromTableTarget(
            values,
            convertPeriodTimestampForTrends(false, currentFilter.selectedAnalysisPeriod),
            currentFilter.selectedAnalysisPeriod,
            currentFilter.onlyAssetsWithIssues,
        )
        dispatch(ActionCreators.receivedRequestedModalData(findMetricDetailsForModal))
    }

    function closeMetricsDetailsModal(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.closeMetricsDetailsModal())
    }

    async function displayMetricsContextData(): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.requestMetricContextData())
        if (state.findMetricDetailsForModal) {
            const listOfAssetsToSearch = state.findMetricDetailsForModal?.assetsAffected.map(
                (element) => element.asset,
            )
            const contextdata = await findContextOfAffectedAssets(
                state.findMetricDetailsForModal?.metricValue as MetricType,
                currentFilter.selectedAnalysisPeriod,
                listOfAssetsToSearch,
            )
            dispatch(ActionCreators.receivedRequestedMetricContextData(contextdata))
        }
    }

    const hasData = state.totalNumberOfMetrics != undefined
    const dataTablesMetrics = hasData
        ? formatTableDataForUI(
              state.pagedDataMetricsTableMap?.get(state.selectedPage || 0),
              allFormattedMetricsForUI,
          ) ?? undefined
        : undefined

    const formattedMetricTypesArray =
        currentFilter.selectedViewScreenType === 'framework'
            ? getFormattedMetricTypesArray(state.metricTypesArray)
            : filterFormattedMetricData(allFormattedMetricsForUI, state.metricTypesArray)

    return {
        loadingInitialDataState: state.loadingInitialDataState,
        loadingFilteredDataState: state.loadingFilteredDataState,
        pageSize: state.pageSize,
        totalNumberOfFilteredMetrics: state.totalNumberOfMetrics,
        selectedPage: state.selectedPage,
        dataTablesHeader: getMetricTableHeaderForUI(currentFilter.analysisTypes),
        dataTablesRow: dataTablesMetrics,
        selectPage: hasData ? selectPage : null,
        totalNumberOfPages: state.totalNumberOfPages,
        dataSummariesMetricsMap: state.dataMetricsMap,
        wholeFleetBenchmarkMap: state.wholeFleetBenchmarkMap,
        allCustomerBenchmarkResponse: state.allCustomerBenchmarks,
        metricTypesArray: formattedMetricTypesArray,
        refreshData: loadInitialPage,
        showFilterBar: state.showFilterBar,
        displayFilterBar,
        setFilter,
        setActiveSavedFilterDropdown,
        isInactive: state.isInactive,
        previousPage: state.previousPage,
        previousMetricTypesSelection: state.previousMetricTypesSelection,
        changePreviousPage,
        displayHighlightedFilterValue,
        highlightedFilerValue: state.highlightedFilerValue,
        idForMetricDetailsModal: state.idForMetricDetailsModal,
        isProcessingMetricDetailsModal:
            state.processingMetricDetailsModal === ProcessingStatus.PROCESSED,
        findMetricDetailsForModal: state.findMetricDetailsForModal,
        modalDetailsExpanded: state.modalDetailsExpanded,
        toggleModalDetailsExpanded,
        displayMetricsDetailsModal,
        closeMetricsDetailsModal,
        loadedMetricContextData: state.loadingMetricContextData === LoadingState.Loaded,
        metricContextData: state.metricContextData,
        displayMetricsContextData,
    }
}

async function getDataForMetricCards(
    dispatch: Dispatch<AllActions>,
    searchedMetricType: MetricType[] | undefined,
    allLocations: Location[],
    searchedPeriod: string,
    locations: Set<LocationIdType> | undefined,
    searchVesselTagTerm: string[],
    searchVesselNameTerm: string,
    selectedViewScreenType: ViewScreenLevelType,
    analysisTypes: AnalysisType,
    assetValues: NodeValue[] | undefined,
    benchmarkType: BenchmarkTrendsType,
): Promise<void> {
    const filteredVessels = filteredVesselIds(
        allLocations,
        locations,
        searchVesselTagTerm ?? [],
        searchVesselNameTerm ?? '',
    )
    const wholeFleetLocations = allLocations?.map((location) => location.location)
    try {
        const response = await REST.post(
            `${METRICS_ENDPOINT}/metrics/summaries`,
            {
                locations: filteredVessels,
                metricTypes: searchedMetricType,
                period: searchedPeriod,
                aggregationLevel:
                    selectedViewScreenType === 'metrics' ? 'metric' : selectedViewScreenType,
                analysisTypes: [analysisTypes],
                assetValues: assetValues,
            },
            {
                timeout: 60000, // Increase the timeout
            },
        )
        const entries = new Map(Object.entries(response.data.summaries)) as MetricSummmaryMap
        if (analysisTypes === AnalysisType.TRENDS && benchmarkType === 'Whole fleet') {
            const wholeFleetBenchmarkResponse = await findWholeFleetData(
                wholeFleetLocations,
                searchedMetricType,
                searchedPeriod,
                selectedViewScreenType,
                assetValues,
            )
            dispatch(ActionCreators.setWholeFleetBenchmark(wholeFleetBenchmarkResponse))
        } else if (analysisTypes === AnalysisType.TRENDS && benchmarkType === 'All customers') {
            const allCustomerBenchmarkResponse = await findAllCustomerBenchmarkData(
                wholeFleetLocations,
                searchedMetricType,
                searchedPeriod,
                selectedViewScreenType,
            )
            dispatch(ActionCreators.setAllCustomerBenchmarkBenchmark(allCustomerBenchmarkResponse))
        }
        // Calculate the score for each entry
        const scoredEntries = Array.from(entries.entries())
            .map(([key, value]) => {
                const score = calculateScoreForCards(value, analysisTypes)
                return {key, value, score}
            })
            .sort((a, b) => a.score - b.score)
        // Convert the sorted array back to a map
        const sortedSummaries = new Map(scoredEntries.map(({key, value}) => [key, value]))

        const summariesKeys: MetricType[] = Array.from(sortedSummaries?.keys()) as MetricType[]
        dispatch(ActionCreators.receivedRequestedPageData(sortedSummaries, summariesKeys))
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        dispatch(ActionCreators.receivedRequestedPageData({} as MetricSummmaryMap, []))
    }
}
async function getTablePagedData(
    dispatch: Dispatch<AllActions>,
    offset: number,
    count: number,
    searchedMetricType: MetricType[] | undefined,
    allLocations: Location[],
    searchedPeriod: string,
    onlyAssetsWithIssues: boolean,
    sortColumn: SortColumnType,
    locations: Set<LocationIdType> | undefined,
    searchVesselTagTerm: string[],
    searchVesselNameTerm: string,
    analysisTypes: AnalysisType,
    assetValues: NodeValue[] | undefined,
): Promise<void> {
    const filteredVessels = filteredVesselIds(
        allLocations,
        locations,
        searchVesselTagTerm ?? [],
        searchVesselNameTerm ?? '',
    )
    const formattedAnalysisType =
        analysisTypes === AnalysisType.WORST_PERFORMING_VESSELS
            ? AnalysisType.TRENDS
            : analysisTypes
    try {
        const response = await REST.post(
            `${METRICS_ENDPOINT}/metrics/tables`,
            {
                locations: filteredVessels,
                metricTypes: searchedMetricType,
                onlyAssetsWithIssues: onlyAssetsWithIssues,
                period: searchedPeriod,
                analysisTypes: [formattedAnalysisType],
                assetValues: assetValues,
                orderBy: {
                    column: sortColumn.column,
                    isAscending: sortColumn.isAscending,
                },
                pagination: {pageSize: count, pageOffset: offset},
            },
            {
                timeout: 60000, // Increase the timeout
            },
        )
        const receivedData = new Map(Object.entries(response.data.tables)) as ApiMetricsTableMap
        const formattedTableData: MetricsResponseData = receivedData?.get(
            formattedAnalysisType,
        ) ?? {
            data: [],
            totalNumberOfItems: 0,
            totalNumberOfPages: 0,
        }

        dispatch(
            ActionCreators.receivedRequestedTablePageData(
                formattedTableData?.data,
                formattedTableData?.totalNumberOfItems || 0,
                formattedTableData?.totalNumberOfPages,
            ),
        )
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        dispatch(ActionCreators.receivedRequestedTablePageData([], 0, 0))
    }
}

function getMetricTableHeaderForUI(analysisType: AnalysisType): MetricTableHeaderForUI[] {
    switch (analysisType) {
        case AnalysisType.SCORECARD:
            return [
                {
                    columnName: MetricsSortType.LOCATION_NAME,
                    columnText: 'Vessel',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.POLICY_NAME,
                    columnText: 'Policy',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.ASSET_NAME,
                    columnText: 'Asset',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.FRAMEWORK,
                    columnText: 'Framework',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.METRIC,
                    columnText: 'Metric',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.TIME_WITH_CONTROL_ISSUE,
                    columnText: 'Number of issues',
                    columnType: 'fixed',
                },
                {
                    columnName: MetricsSortType.TIME_WITH_CONTROL_ISSUE,
                    columnText: 'Total time with issues',
                    columnType: 'sortable',
                },
            ]

        case AnalysisType.BENCHMARK:
            return [
                {
                    columnName: MetricsSortType.LOCATION_NAME,
                    columnText: 'Vessel',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.POLICY_NAME,
                    columnText: 'Policy',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.FRAMEWORK,
                    columnText: 'Framework',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.METRIC,
                    columnText: 'Metric',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.BENCHMARK,
                    columnText: 'Benchmark',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.PERFORMANCE,
                    columnText: 'Vessel performance',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.ASSETS_TO_FIX,
                    columnText: 'Assets to fix',
                    columnType: 'sortable',
                },
            ]
        case AnalysisType.TRENDS:
        case AnalysisType.WORST_PERFORMING_VESSELS:
            return [
                {
                    columnName: MetricsSortType.LOCATION_NAME,
                    columnText: 'Vessel',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.POLICY_NAME,
                    columnText: 'Policy',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.FRAMEWORK,
                    columnText: 'Framework',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.METRIC,
                    columnText: 'Metric',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.PERFORMANCE,
                    columnText: 'Trend period ',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.TREND_CHANGE,
                    columnText: 'Trend change',
                    columnType: 'fixed',
                },
            ]
        case AnalysisType.TARGET:
            return [
                {
                    columnName: MetricsSortType.LOCATION_NAME,
                    columnText: 'Vessel',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.POLICY_NAME,
                    columnText: 'Policy',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.FRAMEWORK,
                    columnText: 'Framework',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.METRIC,
                    columnText: 'Metric',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.VESSEL_TARGET,
                    columnText: 'Vessel target\n (% assets ok)',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.PERFORMANCE,
                    columnText: 'Vessel performance\n (% change from target)',
                    columnType: 'sortable',
                },
                {
                    columnName: MetricsSortType.ASSETS_TO_FIX,
                    columnText: 'Assets to fix\n (for vessel to meet target)',
                    columnType: 'sortable',
                },
            ]
        default:
            return [
                {
                    columnName: MetricsSortType.NONE,
                    columnText: '',
                    columnType: 'fixed',
                },
            ]
    }
}
async function findAssetsCount(
    filteredMetric: MetricType,
    locationId: string,
    searchedPeriod: string,
): Promise<{
    totalNumberOfAssets: number
    totalNumberOfAssetsWithIssue: number
    scoreOfOkAssets: number
}> {
    try {
        const response = await REST.post(`${METRICS_ENDPOINT}/metrics/summaries`, {
            locations: [locationId],
            metricTypes: [filteredMetric],
            period: searchedPeriod,
            aggregationLevel: 'metric',
            analysisTypes: [AnalysisType.WORST_PERFORMING_VESSELS],
        })
        const entries = new Map(Object.entries(response.data.summaries)) as MetricSummmaryMap
        const findSpecificLocation = entries
            .get(filteredMetric)
            ?.worstPerformingLocations?.find(
                (findLocation) => findLocation?.location === locationId,
            )
        const totalNumberOfAssets = findSpecificLocation?.total ?? 0
        const totalNumberOfAssetsWithIssue = findSpecificLocation?.count ?? 0
        const scoreOfOkAssets =
            totalNumberOfAssets === 0
                ? 0
                : Math.round(
                      ((totalNumberOfAssets - totalNumberOfAssetsWithIssue) / totalNumberOfAssets) *
                          100,
                  )
        return {
            totalNumberOfAssets,
            totalNumberOfAssetsWithIssue,
            scoreOfOkAssets,
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        return {
            totalNumberOfAssets: 0,
            totalNumberOfAssetsWithIssue: 0,
            scoreOfOkAssets: 0,
        }
    }
}

async function findDetaisFromTableTarget(
    values:
        | MetricsResponseForAssetStatus
        | MetricsResponseForBenchmark
        | MetricsResponseForTrends
        | MetricsResponseForTarget,
    periodForTarget: TimestampFilterType,
    periodForContext: TimestampFilterType,
    onlyAssetsWithIssues: boolean,
): Promise<MetricModalDetails> {
    try {
        const responseForTarget = await REST.post(`${METRICS_ENDPOINT}/metrics/tables`, {
            locations: [values.location],
            metricTypes: [values.metric],
            period: periodForTarget,
            analysisTypes: [AnalysisType.TARGET],
        })
        const receivedTargetData = new Map(
            Object.entries(responseForTarget.data.tables),
        ) as ApiMetricsTableMap
        const formattedTargetTableData: MetricsResponseForTarget[] = receivedTargetData?.get(
            AnalysisType.TARGET,
        )?.data as MetricsResponseForTarget[]
        const filteredResponse = formattedTargetTableData?.find(
            (item) => item.metric === values.metric && item.location === values.location,
        )

        const {totalNumberOfAssets, totalNumberOfAssetsWithIssue, scoreOfOkAssets} =
            await findAssetsCount(values.metric, values.location, periodForTarget)

        const formattedAssetsAffectedData = await findListOfAffectedAssets(
            values.location,
            values.metric,
            periodForContext,
            onlyAssetsWithIssues,
        )
        if (!filteredResponse) {
            const targetValueFromSchema = await getTargetValueFromPolicyEndpoint(
                values.policy,
                values.metric,
            )
            return {
                ...UNDEFINED_METRIC_MODAL_DETAILS,
                locationName: values.locationName,
                appliedPolicyName: values.policyName,
                metricName:
                    values.formattedMetricForUI.displayName ??
                    StringUtils.capitaliseString(values.metric.replace(/([A-Z])/g, ' $1')),
                metricValue: values.metric,
                metricCategory: values.formattedMetricForUI.metricGroup,
                numberOfAssets: totalNumberOfAssets,
                assetsWithIssues: totalNumberOfAssetsWithIssue,
                scoreOfOkAssets: scoreOfOkAssets,
                targetForThisMetric: `${targetValueFromSchema ?? 100}%`,
                assetsAffected: formattedAssetsAffectedData ?? [],
            }
        }
        const vesselWithinTarget = filteredResponse?.target > 0 ? 'Yes' : 'No'
        const changeOverTrendPeriod =
            getFormatedPercentNumber(filteredResponse?.performance) ?? 'Unknown'
        const targetForThisMetric = `${filteredResponse?.target ?? 100}%`
        const assetsToFix = filteredResponse?.assetsToFix ?? 0
        return {
            metricName:
                values.formattedMetricForUI.displayName ??
                StringUtils.capitaliseString(values.metric.replace(/([A-Z])/g, ' $1')),
            metricValue: values.metric,
            metricCategory: values.formattedMetricForUI.metricGroup,
            locationName: filteredResponse?.locationName ?? values.locationName,
            numberOfAssets: totalNumberOfAssets,
            assetsWithIssues: totalNumberOfAssetsWithIssue,
            scoreOfOkAssets: scoreOfOkAssets,
            appliedPolicyName: filteredResponse?.policyName ?? values.policyName,
            vesselWithinTarget: vesselWithinTarget,
            changeOverTrendPeriod: changeOverTrendPeriod,
            targetForThisMetric: targetForThisMetric,
            assetsToFix: assetsToFix,
            assetsAffected: formattedAssetsAffectedData ?? [],
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        return UNDEFINED_METRIC_MODAL_DETAILS
    }
}

async function getTargetValueFromPolicyEndpoint(
    givenPolicyIdentity: GuidType,
    givenMetric: MetricType,
): Promise<number> {
    try {
        const policyResponse = await REST.get(`${METRICS_POLICY_ENDPOINT}/${givenPolicyIdentity}`)
        const schemasResponse = await REST.get(`${METRICS_SCHEMA_ENDPOINT}`)

        const receivedPolicy = policyResponse.data as MetricsPolicy
        const receivedSchemas = schemasResponse.data as MetricsSchema[]

        const matchingSchema = receivedSchemas.find((schema) => schema.name === givenMetric)

        if (matchingSchema) {
            const matchingPolicy = receivedPolicy.metricPolicies.find(
                (policy) => policy.metricConfigId === matchingSchema.identity,
            )

            if (matchingPolicy) {
                return matchingPolicy.target
            }
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
    }
    return 100
}

async function findWholeFleetData(
    wholeFleetLocations: string[],
    searchedMetricType: MetricType[] | undefined,
    searchedPeriod: string,
    selectedViewScreenType: ViewScreenLevelType,
    assetValues: NodeValue[] | undefined,
): Promise<MetricSummmaryMap> {
    try {
        const wholeFleetBenchmarkResponse = await REST.post(
            `${METRICS_ENDPOINT}/metrics/summaries`,
            {
                locations: wholeFleetLocations,
                metricTypes: searchedMetricType,
                period: searchedPeriod,
                aggregationLevel:
                    selectedViewScreenType === 'metrics' ? 'metric' : selectedViewScreenType,
                analysisTypes: [AnalysisType.TRENDS],
                assetValues: assetValues,
            },
            {
                timeout: 60000, // Increase the timeout
            },
        )

        return new Map(
            Object.entries(wholeFleetBenchmarkResponse.data.summaries),
        ) as MetricSummmaryMap
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        return {} as MetricSummmaryMap
    }
}
async function findAllCustomerBenchmarkData(
    wholeFleetLocations: string[],
    searchedMetricType: MetricType[] | undefined,
    searchedPeriod: string,
    selectedViewScreenType: string,
): Promise<AllCustomerBenchmarksData[]> {
    try {
        const formattedFrameworkQuerry =
            selectedViewScreenType === 'framework' ? searchedMetricType : undefined
        const formattedMetricQuerry =
            selectedViewScreenType === 'metrics' ? searchedMetricType : undefined
        const allCustomerBenchmarkResponse = await REST.post(
            `${METRICS_ENDPOINT}/customer_benchmarks/find`,
            {
                locations: wholeFleetLocations,
                framework: formattedFrameworkQuerry,
                metric: formattedMetricQuerry,
                period: searchedPeriod,
                aggregationLevel:
                    selectedViewScreenType === 'metrics' ? 'metric' : selectedViewScreenType,
            },
        )

        return (allCustomerBenchmarkResponse.data.data as AllCustomerBenchmarksData[]).map(
            (item) => {
                return {
                    framework: item.framework,
                    metric: item.metric,
                    period: item.period,
                    benchmark: item.benchmark,
                    metricType:
                        selectedViewScreenType === 'framework' ? item.framework : item.metric,
                }
            },
        )
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        return []
    }
}

async function findListOfAffectedAssets(
    searchedLocation: LocationIdType,
    searchedMetricType: MetricType,
    searchedPeriod: TimestampFilterType,
    onlyAssetsWithIssues: boolean,
): Promise<AssetsAffected[]> {
    try {
        const allAffectedAssetsResponse = await REST.post(
            `${METRICS_ENDPOINT}/metrics/statePeriods`,
            {
                location: searchedLocation,
                metric: searchedMetricType,
                period: searchedPeriod,
                onlyAssetsWithIssues: onlyAssetsWithIssues,
            },
        )
        return allAffectedAssetsResponse.data as AssetsAffected[]
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        return []
    }
}

async function findContextOfAffectedAssets(
    searchedMetricType: MetricType,
    searchedPeriod: TimestampFilterType,
    searchedAssets: string[],
): Promise<MetricContextDataMap> {
    try {
        const contextDataResponse = await REST.post(`${METRICS_ENDPOINT}/metrics/data`, {
            metricType: searchedMetricType,
            period: searchedPeriod,
            assets: searchedAssets.length === 0 ? null : [...new Set(searchedAssets)],
        })
        const contextMap = new Map(Object.entries(contextDataResponse.data)) as MetricContextDataMap
        return contextMap
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        return new Map()
    }
}

function getFormattedMetricTypesArray(mainMetrics: MetricType[]): FormattedMetricData[] {
    const formattedMetricData = mainMetrics.map((element) => {
        return {
            name: element,
            displayName: getMainMetricsDisplayName(element),
            metricGroup: element,
        }
    })
    return formattedMetricData
}

function formatTableDataForUI(
    tableData: MetricsResponse | undefined,
    allFormattedMetricsForUI: FormattedMetricData[],
): MetricsResponse | undefined {
    if (!tableData) {
        return undefined
    } else {
        const formattedMetricData = tableData.map((tableElement) => {
            const findMatchMetric = allFormattedMetricsForUI.find(
                (element) => element.name === tableElement.metric,
            )
            return {
                ...tableElement,
                formattedMetricForUI: {
                    name: findMatchMetric?.name ?? tableElement.metric,
                    displayName: findMatchMetric?.displayName ?? tableElement.metric,
                    metricGroup: findMatchMetric?.metricGroup ?? tableElement.framework,
                },
            }
        }) as MetricsResponse
        return formattedMetricData
    }
}
