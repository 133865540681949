import {useDispatch} from 'react-redux'
import {FilterBarButton} from '../../../../components/filter-button/filter-button'
import {useDimensions} from '../../../../contexts/dimensions/use-dimensions'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {AuditLogPageType} from '../../../../store/state/audit-log/state'
import {activeFilterSelector} from '../../../../store/state/metrics-filter-beta/selectors'
import {activeVesselFilterSelector} from '../../../../store/state/vessel-filter/selectors'
import {usePagedMetricsBeta} from '../../contexts/use-paged-metrics-beta'
import {ContentWrapper} from './data-actions.styled'
import {SearchedPeriod} from './searched-period/searched-period'
import {logButtonClickFor} from '../../../../store/state/audit-log/action-creators'

export function FilterBarButtonWrapper(): JSX.Element {
    const {displayFilterBar, showFilterBar, closeMetricsDetailsModal} = usePagedMetricsBeta()
    const {width} = useDimensions()
    const activeVesselFilter = useTypedSelector(activeVesselFilterSelector)
    const activeFilter = useTypedSelector(activeFilterSelector)
    const dispatch = useDispatch()
    return (
        <ContentWrapper width={width} id="FilterBarButtonWrapper">
            <FilterBarButton
                onClick={() => {
                    displayFilterBar(!showFilterBar)
                    closeMetricsDetailsModal()
                    dispatch(logButtonClickFor('Open Filter', AuditLogPageType.METRICS))
                }}
                active={activeFilter || activeVesselFilter}
            />
            <SearchedPeriod />
        </ContentWrapper>
    )
}
