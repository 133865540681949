import {useContext} from 'react'
import {warn} from '../../../../helpers/logging'
import * as ActionCreators from './state/action-creators'
import {useIncidentsPolicyResult} from './use-incidents-policy-output'
import {IncidentsPolicyContext} from './incidents-policy-context'
import {GuidType} from '../../../../values/generic-type-defintions'
import {IncidentSeverityValues} from '../../../../values/incident-response-values'
import useTypedSelector from '../../../../hooks/use-typed-selector'
import {incidentTypesSelector} from '../../../../store/state/incident-types/selectors'
import {isEqual} from 'lodash'
import {REST} from '../../../..'
import {reverseFormatArray} from '../data-helper'
import {usePolicyManagement} from '../policy-management/use-policy-management'
import {PolicyType} from '../policy-management/type/policy-management-state'
import {getFormattedIncidentsPolicyPlaybooksForUI} from './type/incidents-recommendation-playbooks'
import {getFileAttachments} from '../metrics-policy/use-metrics-policy'

const INCIDENT_POLICY_ENDPOINT = '/api/v1/incidentResponses/policies'
const INCIDENT_POLICY_RECOM_PLAYBOOKS_ENDPOINT = '/api/v1/incidentResponses/recommendationPlaybooks'

export function useIncidentsPolicy(): useIncidentsPolicyResult {
    const {state, dispatch} = useContext(IncidentsPolicyContext)
    const {requestDataToBeFecthed, fetchPolicyData, showPolicyDetailModal} = usePolicyManagement()
    if (state == undefined || dispatch == undefined) {
        throw new Error('useIncidentsPolicy must be used within a IncidentsPolicyContext')
    }

    const allTypeNames = useTypedSelector(incidentTypesSelector).map((type) => type.name)

    function findIncidentsPolicyDetails(identity: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        dispatch(ActionCreators.requestIncidentPolicyData())
        REST.get(`${INCIDENT_POLICY_ENDPOINT}/${identity}`)
            .then((response) => {
                dispatch(
                    ActionCreators.receivedRequestedIncidentPolicyData(
                        identity,
                        allTypeNames,
                        response.data,
                    ),
                )
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.error(error))
    }
    async function findIncidentRecommendationPlaybooksTypes(): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        try {
            const typesResponse = await REST.get(`${INCIDENT_POLICY_RECOM_PLAYBOOKS_ENDPOINT}`)
            const receivedtTypesResponse = typesResponse.data
            dispatch(ActionCreators.setRecommendationPlaybooksTypes(receivedtTypesResponse))
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }
    function changePolicyName(value: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangePolicyName(value))
    }

    function changeCcVesselEmailsTo(value: string[]): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeCcVesselEmailsTo(value))
    }

    function changeReassignUser(value: GuidType | null): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeReassignUser(value))
    }

    function changeAutoAssignUser(value: GuidType | null): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeAutoAssignUser(value))
    }

    function enableAutoAssign(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.enableAutoAssign(value))
    }

    function selectSeverity(
        incidentType: string,
        severity: IncidentSeverityValues,
        value: boolean,
    ): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.selectSeverity(incidentType, severity, value))
    }

    function updateIncidentPolicyDetails(identity: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        const sendReminderAfterDuration = state.enableIncidentReminder
            ? state.activeIncidentPolicyData.sendReminderAfterDuration
            : null
        const sendEscalationReminderAfterDuration = state.enableIncidentReminder
            ? state.activeIncidentPolicyData.sendEscalationReminderAfterDuration
            : null
        const escalateToUser = state.enableIncidentReminder
            ? state.activeIncidentPolicyData.escalateToUser
            : null

        REST.put(`${INCIDENT_POLICY_ENDPOINT}/${identity}`, {
            name: state.activeIncidentPolicyData.name,
            defaultPolicy: state.activeIncidentPolicyData.defaultPolicy,
            enabledForIncidentManagement:
                state.activeIncidentPolicyData.enabledForIncidentManagement,
            otherwiseAssignToUser: state.activeIncidentPolicyData.otherwiseAssignToUser,
            reassignToUser: state.activeIncidentPolicyData.reassignToUser,
            types: reverseFormatArray(state.formattedIncidentPolicyTypes),
            sendReminderAfterDuration: sendReminderAfterDuration,
            sendEscalationReminderAfterDuration: sendEscalationReminderAfterDuration,
            escalateToUser: escalateToUser,
            ccVesselEmailsTo: state.activeIncidentPolicyData.ccVesselEmailsTo,
        })
            .then(() => {
                requestDataToBeFecthed(true)
                fetchPolicyData()
                findIncidentsPolicyDetails(identity)
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.error(error))
    }

    function enableIncidentReminderFn(value: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.enableIncidentReminder(value))
    }

    function changeReminderDuration(value: number | null): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeReminderDuration(value))
    }

    function changeEscalateToUser(value: GuidType | null): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setChangeEscalateToUser(value))
    }

    function setDefaultIncidentPolicyData(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.setDefaultPolicyData(allTypeNames))
    }
    function addNewIncidentPolicyDetails(): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }

        const sendReminderAfterDuration = state.enableIncidentReminder
            ? state.activeIncidentPolicyData.sendReminderAfterDuration
            : null
        const sendEscalationReminderAfterDuration = state.enableIncidentReminder
            ? state.activeIncidentPolicyData.sendEscalationReminderAfterDuration
            : null
        const escalateToUser = state.enableIncidentReminder
            ? state.activeIncidentPolicyData.escalateToUser
            : null

        REST.post(INCIDENT_POLICY_ENDPOINT, {
            name: state.activeIncidentPolicyData.name,
            defaultPolicy: state.activeIncidentPolicyData.defaultPolicy,
            enabledForIncidentManagement:
                state.activeIncidentPolicyData.enabledForIncidentManagement,
            otherwiseAssignToUser: state.activeIncidentPolicyData.otherwiseAssignToUser,
            reassignToUser: state.activeIncidentPolicyData.reassignToUser,
            types: reverseFormatArray(state.formattedIncidentPolicyTypes),
            sendReminderAfterDuration: sendReminderAfterDuration,
            sendEscalationReminderAfterDuration: sendEscalationReminderAfterDuration,
            escalateToUser: escalateToUser,
            ccVesselEmailsTo: state.activeIncidentPolicyData.ccVesselEmailsTo,
        })
            .then((response) => {
                requestDataToBeFecthed(true)
                fetchPolicyData()
                showPolicyDetailModal(PolicyType.INCIDENT, response.data.identity)
                findIncidentsPolicyDetails(response.data.identity)
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.error(error))
    }
    function downloadPlaybookFile(identity: GuidType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        getFileAttachments(identity)
    }
    async function removeCustomFile(
        policyIdentity: GuidType,
        recommendationPlaybookIdentity: GuidType,
    ): Promise<void> {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        try {
            const response = await REST.delete(
                `${INCIDENT_POLICY_RECOM_PLAYBOOKS_ENDPOINT}/${policyIdentity}/${recommendationPlaybookIdentity}`,
            )
            if (response.data === true) {
                requestDataToBeFecthed(true)
                fetchPolicyData()
                findIncidentsPolicyDetails(policyIdentity)
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error)
        }
    }

    const isValidIncidentReminder = state.enableIncidentReminder
        ? state.activeIncidentPolicyData.sendReminderAfterDuration !== null &&
          state.activeIncidentPolicyData.escalateToUser !== null
        : true

    const isIncidentChanged =
        !isEqual(state.activeIncidentPolicyData, state.initialIncidentPolicyDetails) ||
        !isEqual(
            reverseFormatArray(state.formattedIncidentPolicyTypes),
            state.initialIncidentPolicyDetails.types,
        ) ||
        !isEqual(state.enableIncidentReminder, state.initialEnableIncidentReminder) ||
        !isEqual(
            state.activeIncidentPolicyData?.ccVesselEmailsTo,
            state.initialIncidentPolicyDetails?.ccVesselEmailsTo,
        )

    const isValidUpdatedIncident = isIncidentChanged && isValidIncidentReminder

    const isSavAbleNewIncident =
        state.activeIncidentPolicyData.name.length > 0 && isValidIncidentReminder
    const formattedIncidentsPolicyPlaybooksForUI = getFormattedIncidentsPolicyPlaybooksForUI(
        state.activeIncidentPolicyData.recommendationPlaybooks,
        state.incidentPolicyPlaybooksTypes,
        state.activeIncidentPolicyData.identity,
    )
    return {
        loadingRequestedIncidentsDataState: state.loadingRequestedIncidentsDataState,
        incidentResponsePolicyData: state.incidentResponsePolicyData,
        activeIncidentPolicyData: state.activeIncidentPolicyData,
        initialIncidentPolicyData: state.initialIncidentPolicyDetails,
        displayIncidentPolicyData: state.displayIncidentPolicyDetails,
        changePolicyName,
        changeCcVesselEmailsTo,
        changeReassignUser,
        changeAutoAssignUser,
        enableAutoAssign,
        formattedIncidentPolicyTypes: state.formattedIncidentPolicyTypes,
        selectSeverity,
        updateIncidentPolicyDetails,
        enableIncidentReminderFn,
        changeReminderDuration,
        changeEscalateToUser,
        enableIncidentReminder: state.enableIncidentReminder,
        isIncidentChanged,
        isValidIncidentReminder,
        addNewIncidentPolicyDetails,
        isSavAbleNewIncident,
        isValidUpdatedIncident,
        findIncidentsPolicyDetails,
        setDefaultIncidentPolicyData,
        findIncidentRecommendationPlaybooksTypes,
        formattedIncidentsPolicyPlaybooksForUI,
        downloadPlaybookFile,
        removeCustomFile,
    }
}
